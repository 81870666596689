import Rellax from "rellax";

class Parallax {

    run() {
        this.hero();
        this.speed();
    }

    hero() {
      const whiteDec = new Rellax('.big-hero-dec-w', {
        speed: -2,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });

      const redDec = new Rellax('.big-hero-dec-r', {
        speed: 1,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
    }

    speed() {
        const speedElement = new Rellax('.speed');
    };

}

const parallax = new Parallax();
export default parallax;
