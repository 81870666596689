import $ from 'jquery';
import TweenMax from "gsap/TweenMax";
import lightGallery from "../../../bower_components/lightgallery/dist/js/lightgallery";

class App {

    run() {
        this.apply();
        this.blocks();
        this.menu();
        this.modal();
        this.modalcodes();
        this.check();
        this.counter();
        this.countbreadcrumbs();
        this.filter();
        this.stopVideoonCloseModal();
        this.populateSearchTableSelects();
        this.populateModuleSearchTableSelects();
    }

    apply() {
        // $(window).on("scroll", function() {
        //   if($(window).scrollTop() === 0) {
        //     $('.header').removeClass('fixed');
        //   } else {
        //     $('.header').addClass('fixed');
        //   }
        // })
    }

    blocks() {
        $('.blocks--element').each(function () {
            if (Foundation.MediaQuery.atLeast('medium')) {
                if ($(this).children().hasClass("blocks--element__hover") === true) {
                    $(this).addClass("blocks-hover");
                } else if ($(this).children().hasClass("blocks--element__hover") === false && this.hasAttribute("data-link") === true) {
                    const link = $(this).data("link");
                    $(this).wrap("<a tabindex='0' href=" + link + "></a>");
                }
            } else {
                if (this.hasAttribute("data-link") === true) {
                    const link = $(this).data("link");
                    $(this).wrap("<a tabindex='0' href=" + link + "></a>");
                }
            }

        });

        if ($(window).width() <= 1024) {

            $('.about-content').removeClass('speed');

        }


        function printDiv(printDiv) {
            console.log('PrintDiv Parameter', printDiv);

            var divToPrint = document.getElementsByClassName('' + printDiv + '');

            console.log('DIV TO PRINT', divToPrint);

            var newWin = window.open('', 'Print-Window');

            newWin.document.open();

            newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

            newWin.document.close();

            setTimeout(function () {
                newWin.close();
            }, 10);

        }


        /*$('#printPage').on('click', function () {
            printDiv('main');
            console.log('PrintDiv Function');
            //return false;
        });*/

        $('#printPageDownload').on('click', function () {
            window.print();
        });


    }

    menu() {
        if (Foundation.MediaQuery.atLeast('large')) {
            $(".header__hamburger").on("click", function () {
                const menu = $(".fullmenu");
                const left = $(".fullmenu .fullmenu--nav");
                const right = $(".fullmenu .fullmenu--image");
                const fullMenu = $(".fullmenu .fullmenu--nav__menu");
                const fullFooter = $(".fullmenu .fullmenu--nav__footer");
                const openMenu = new TimelineMax();

                menu.css("display", "flex");
                menu.addClass("active");

                openMenu.to(left, 1, {
                    width: "50%",
                    ease: Power4.easeOut
                }, "init")
                    .to(right, .8, {
                        width: "50%",
                        ease: Power4.easeOut
                    }, "init")
                    .to(fullMenu, .4, {
                        opacity: 1,
                        ease: Power4.easeOut,
                    }, "-=0.7")
                    .to(fullFooter, .4, {
                        opacity: 1,
                        ease: Power4.easeOut,
                    }, "-=0.7")
            })

            $("#menu--sub01").on("click", function () {
                const value = $(this).attr("id");
                if ($(this).closest(".header").find(".menu--sub").hasClass("open") === true) {
                    $(this).closest(".header").find("div[data-open=" + value + "]").removeClass("open");
                } else {
                    $(this).closest(".header").find("div[data-open=" + value + "]").addClass("open");
                }
                return false;
            });

            $(".header").on("mouseleave", function () {
                $(this).children(".menu--sub").removeClass("open");
                $(this).find("[open-menu]").removeClass('opened');
                $(this).find("a").removeClass('active');
            });

        } else {
            $(".header__hamburger").on("click", function () {
                const menu = $(".fullmenu");
                const left = $(".fullmenu .fullmenu--nav");
                const right = $(".fullmenu .fullmenu--image");
                const fullMenu = $(".fullmenu .fullmenu--nav__menu");
                const fullFooter = $(".fullmenu .fullmenu--nav__footer");
                const openMenu = new TimelineMax();

                menu.css("display", "flex");
                menu.addClass("active");

                openMenu.to(left, .8, {
                    width: "100%",
                    ease: Power4.easeOut
                })
                    .to(fullMenu, .5, {
                        opacity: 1,
                        ease: Power4.easeOut,
                    }, "-=0.5")
                    .to(fullFooter, .5, {
                        opacity: 1,
                        ease: Power4.easeOut,
                    }, "-=0.5")
            })
        }

        $(".fullmenu--close").on("click", function () {
            const menu = $(".fullmenu");
            const left = $(".fullmenu .fullmenu--nav");
            const right = $(".fullmenu .fullmenu--image");
            const fullMenu = $(".fullmenu .fullmenu--nav__menu");
            const fullFooter = $(".fullmenu .fullmenu--nav__footer");
            const openMenu = new TimelineMax();

            menu.removeClass("active");

            openMenu.to(left, .6, {
                width: "0%",
                ease: Power2.easeOut
            }, "init")
                .to(fullMenu, .2, {
                    opacity: 0,
                    ease: Power4.easeOut,
                }, "-=0.5")
                .to(fullFooter, .2, {
                    opacity: 0,
                    ease: Power4.easeOut,
                }, "-=0.5")
                .to(right, .6, {
                    width: "0%",
                    ease: Power2.easeOut,
                    onComplete: function () {
                        menu.hide();
                    }
                }, "init")


        })

        $(".menu-pdp a").on("click", function () {
            const value = $(this).attr("id");
            const header = $(".header").height();

            $("html, body").animate({scrollTop: $('.' + value).offset().top - header - 50}, 1000);
        })

        $(".menu--sub__level1 a").on("click", function () {
            const name = $(this).attr("id");
            $(this).parent().parent().find("a").removeClass("active");
            $(this).addClass("active");
            $(this).closest(".menu--sub").find("[open-menu]").removeClass("opened");
            $(this).closest(".menu--sub").find("[open-menu=" + name + "]").addClass("opened");
        })
    }

    modal() {
        $(".modal").on("click", function () {
            const name = $(this).attr("id");
            $(document).find("[open-modal=" + name + "]").addClass("active");
        });

        $(".close-modal").on("click", function () {
            $(this).closest(".modal-searchbox").removeClass("active");
            $(this).closest(".modal-newsletter").removeClass("active");
        });
    }

    modalcodes() {
        $("#openOfferta").on("click", function () {
            var selectedchain = $('.selectedChain').text();
            //var kitcodes = $('#kitCodes').text();

            $('#modalcatenecodes').text(selectedchain);

            var kitcodes = "";
            var temp = "";
            var res = "";

            $(".kitCodes").each(function (index) {
                temp = "<span>" + $(this).text() + "</span><br/>";
                //console.log('This Text', $(this).text() + "<br/>")
                kitcodes += temp;
            });

            if (selectedchain) {
                $('#modalcatenecodes').text(selectedchain);
            } else {
                $('#modalcatenecodes').text('Non hai scelto niente su configuratore');
            }

            if (kitcodes) {
                $('#modalkitcodes').html(kitcodes);
            } else {
                $('#modalkitcodes').text('Non hai scelto niente su configuratore');
            }

            /*console.log('Selected Chain', selectedchain);
            console.log('Selected Kit Codes', kitcodes);*/

        });
    }

    check() {
        $(".inp-box").change(function () {
            if (this.checked) {
                $('.checkinborder').css('background-color', '#ee1c25');
                $('.checkin').css('display', 'block');
                //console.log("I have checked --> ", this);
            }
            if (!this.checked) {
                $('.checkinborder').css('background-color', 'transparent');
                $('.checkin').css('display', 'none');
                //console.log("I have unchecked --> ", this);
            }
        });
    }

    filter() {

        var singlecat;
        var singletip;
        var singlepart;

        function getenabledCategories() {
            var enabledcat = "";

            $(".checkbook").each(function (index) {
                var cat = $(this).attr('data-category');

                if (this.checked && cat) {
                    enabledcat += cat + ",";
                    //console.log("Category THIS --> ", cat);
                }

            });

            //console.log("Enabled Categories --> ", enabledcat);

            return enabledcat;

        }

        function getenabledTipologies() {
            var enabledtip = "";

            $(".checkbook").each(function (index) {
                var tip = $(this).attr('data-tipology');

                if (this.checked && tip) {
                    enabledtip += tip + ",";
                    //console.log("Tipology THIS --> ", tip);
                }

            });

            //console.log("Enabled Tipologies --> ", enabledtip);

            return enabledtip;

        }

        function getenabledPartners() {
            var enabledpart = "";

            $(".checkbook").each(function (index) {
                var part = $(this).attr('data-partners');

                if (this.checked && part) {
                    enabledpart += part + ",";
                    //console.log("Partner THIS --> ", part);
                }

            });

            //console.log("Enabled Partners --> ", enabledpart);

            return enabledpart;

        }

        function showBooks(target)
        {
            var words = target.split(',').map(function(item){
                return item.trim();
            });

            console.log(words);

            $(".books").each(function (index) {
                singletip = $(this).attr('data-tipology') + " " + $(this).attr('data-category');

                var found = true;
                words.forEach(function(word){
                    found = found && singletip.includes(word)
                });
                console.log(singletip);
                console.log("/***************/");
                if (found) {
                    $(this).show(200);
                }
            });
        }

        function showTipologies(target) {
            //console.log("Target Tipologies --> ", target);
            var firstarray = [];
            firstarray = target.split(',');
            firstarray.slice(0, -1);

            var array = firstarray.filter(function (v) {
                return v !== ''
            });

            //console.log("Target array --> ", array);

            var a;

            var value;

            var n;

            for (a in array) {

                $(".books").each(function (index) {
                    singletip = $(this).attr('data-tipology');
                    value = array[a];
                    n = singletip.indexOf(value);

                    //console.log('SINGLETIP ON EACH', singletip);
                    //console.log('VALUE', value);
                    //console.log('INDEXOF', n);

                    //if (singletip == array[a]) {
                    if (n > -1) {
                        $(this).show(200);
                    }
                });
            }

        }

        function showCategories(target) {
            //console.log("Target Categories --> ", target);
            var firstarray = [];
            firstarray = target.split(',');
            firstarray.slice(0, -1);

            var array = firstarray.filter(function (v) {
                return v !== ''
            });

            //console.log("Target array --> ", array);

            var a;

            var value;

            var n;

            for (a in array) {

                $(".books").each(function (index) {
                    singlecat = $(this).attr('data-category');
                    value = array[a];
                    n = singlecat.indexOf(value);

                    //console.log('SINGLECAT ON EACH', singlecat);
                    //console.log('VALUE', value);
                    //console.log('INDEXOF', n);

                    //if (singlecat == array[a]) {
                    if (n > -1) {
                        $(this).show(200);
                    }
                });
            }

        }

        function showPartners(target) {
            //console.log("Target Partners --> ", target);
            var firstarray = [];
            firstarray = target.split(',');
            firstarray.slice(0, -1);

            var array = firstarray.filter(function (v) {
                return v !== ''
            });

            //console.log("Target array --> ", array);

            var a;

            var value;

            var n;

            for (a in array) {

                $(".books").each(function (index) {
                    singlepart = $(this).attr('data-partners');
                    value = array[a];
                    n = singlepart.indexOf(value);

                    //console.log('SINGLECAT ON EACH', singlepart);
                    //console.log('VALUE', value);
                    //console.log('INDEXOF', n);

                    //if (singlepart == array[a]) {
                    if (n > -1) {
                        $(this).show(200);
                    }
                });
            }

        }

        function removefilter() {
            var enabledtip = getenabledTipologies();
            var enabledcat = getenabledCategories();
            //var enabledpart = getenabledPartners();

            $('.books').hide(50);

            showBooks(enabledcat + "," + enabledtip);

            //showCategories(enabledcat);

            //showTipologies(enabledtip);

            //showPartners(enabledpart);

            //console.log('Enabled Categories after Hide All', enabledcat);
            //console.log('Enabled Tipologies after Hide All', enabledtip);
            //console.log('Enabled Partners after Hide All', enabledpart);

        }

        $(document).ready(function () {

            $(".lightgallery").lightGallery();


            if (window.location.href.indexOf("download") != -1) {

                var allunchecked = false;

                var countChecked = function () {
                    var n = $(".checkbook:checkbox:checked").length;
                    //console.log('There are ' + n +' checks')
                    if (n == 0) {
                        allunchecked = true;
                    } else {
                        allunchecked = false;
                    }
                };

                // $( "input[type=checkbox]" ).on( "click", countChecked() );


                $(".checkbook").change(function () {

                    countChecked();

                    if (allunchecked == true) {
                        $('.books').show(200);
                        //console.log('All Product Books Showed')
                    } else {
                        allunchecked = false;
                        removefilter();
                    }

                });

            }
        });
    }

    countbreadcrumbs() {
        $(window).on('load', function () {
            var items = $('.breadcrumbs');
            if (items.length > 1) {
                items.first().remove();
                //console.log('We have ' + items.length + ' breadcrumbs on this page');
            }
        });
    }

    //Soluzione Per Fare Stop del embed video on Video Modale
    stopVideoonCloseModal() {
        function iframesrc() {
            $("iframe").each(function() {
                var src= $(this).attr('src');
                $(this).attr('src',src);
            });
        }
        $(".videomodal .close-modal").click(function () {
            //$('video').trigger('pause');
            iframesrc();
        });
    }

    populateSearchTableSelects() {

        function getenabledfilters() {
            var enabledfilters = [];
            var filterValue = "";
            var filterKey = "";

            $(".table-search").each(function (index) {
                var tableid = $(this).attr('data-table');
                var productid = $(this).attr('data-product');
                var productvalue = $(this).val();

                    //console.log('The Select has data-table '+tableid+' , data-product '+productid+' and Value '+productvalue)

                if (productvalue) {
                    filterValue = productvalue;
                    filterKey = productid;
                    //enabledfilters.push(newFilter);
                    enabledfilters.push({
                        slug: filterKey,
                        value:filterValue
                    });
                    //console.log("Value THIS --> ", productvalue);
                }

            });

            //console.log("Enabled Filters --> ", enabledfilters);

            return enabledfilters;

        }

        function gettdValuesfromTable(table, data) {
            var values = [];
            var newFilter = "";

            //console.log('This is the Data Table For Closest Table ' + table );
            var tableget = $("table[data-table="+table+"]");
            var searchstring = "> tbody > tr > td[data-product="+data+"]";
            //console.log('Search String   ',searchstring);
            tableget.find(searchstring).each(function () {

                newFilter = $(this).html();

                if($.inArray(newFilter, values)<0) {
                    //add to array
                    values.push(newFilter); // <- basic JS see Array.push
                    //console.log('This Filter Pushed ', newFilter);
                    //console.log('Values After Pushed  ', values);

                }
            });

            //console.log('This Values Pushed ', values);

            return values;

        }

        function showSelectedValues(table,slug,value) {

            //console.log('Slug: ', slug);
            //console.log('Value: ', value);
            //console.log('Table: ', table);

            $("#table"+table+" tr td").each(function() {
                var tdValue = $(this).html();
                var tdSlug = $(this).attr('data-product');
                //console.log('This td has the slug: '+tdSlug+' and the Value '+tdValue);
                //console.log('Td Typeof Slug: '+ typeof tdSlug+' and typeof Value '+ typeof tdValue);
                //console.log('Function Typeof Slug: '+ typeof slug+' and typeof Value '+ typeof value);

                if (tdValue === value && tdSlug === slug) {
                    $(this).closest('tr').show(200);
                    //console.log('We found it, The Parent', $(this).parent());
                }

            });

        }

        function resetfilter(id) {
            $('#table'+id+' tr td').each(function() {
                $(this).closest('tr').hide(50);
                $(this).closest('tr').show(200);
            });
            $('select[id*='+id+']').each( function () {
                $(this).find('option:eq(0)').prop('selected', true);
            });
        }

        $(document).ready(function () {
            var items = $('.table-search');
            var dataproduct;
            var datatable;

            var options;

            if (items) {
                $('.table-search').each(function (){
                    dataproduct = $(this).attr("data-product");
                    //console.log('This is the Data Product For this Select ' + dataproduct );
                    datatable = $(this).attr('data-table');
                    //console.log('This is the Data Table For this Select ' + dataproduct );
                    options = gettdValuesfromTable(datatable, dataproduct);
                    //console.log('Select Options ' + options );
                    //console.log('TypeOF Options ' + typeof options );
                    //console.log("THISSSSS Select", $(this).html());
                    $.each(options, function(key, value) {
                        //console.log("THISSSSS Value", value);
                        //console.log("THISSSSS Key", key);
                        var thisselect = $('#'+datatable+'-'+dataproduct);
                        var selectTable = thisselect.attr('data-table');
                        //console.log("THISSSSS Select", thisselect);

                        thisselect.append(`<option value="${value}"> 
                                       ${value} 
                                  </option>`);
                    });

                });
            }

            $('.resetfiltertable').on('click', function () {
                var tableid = $(this).attr("id");
                if(tableid) {
                    resetfilter(tableid);
                }
            });

            $(".table-search").change(function () {
                var filterdata = getenabledfilters();
                var tableid = $(this).attr("data-table");

                if (filterdata) {
                    $('#table'+tableid+' tr td').each(function() {
                        $(this).closest('tr').hide(50);
                    });
                }

                $(filterdata).each(function (){
                    var filterSlug = "";
                    var filterValue = "";
                    var filterTable = tableid;

                    if ($(this)[0].slug) {
                        filterSlug = $(this)[0].slug;
                    }

                    if ($(this)[0].value) {
                        filterValue = $(this)[0].value;
                    }
                    //console.log('Slug: ', filterSlug);
                    //console.log('Value: ', filterValue);
                    //console.log('Table: ', tableid);
                    showSelectedValues(filterTable, filterSlug, filterValue);

                });

                //console.log('Select is Changed');
                //console.log('Table Id ', tableid);
            });

            });
    }

    populateModuleSearchTableSelects() {

        function getenabledfilters() {
            var enabledfilters = [];
            var filterValue = "";
            var filterKey = "";

            $(".alltable-search").each(function (index) {
                var tableid = $(this).attr('data-table');
                var productid = $(this).attr('data-product');
                var productvalue = $(this).val();

                    //console.log('The Select has data-product '+productid+' and Value '+productvalue)

                if (productvalue) {
                    filterValue = productvalue;
                    filterKey = productid;
                    enabledfilters.push({
                        slug: filterKey,
                        value:filterValue
                    });
                }

            });

            //console.log("Enabled Filters --> ", enabledfilters);

            return enabledfilters;

        }

        function gettdValuesfromTable(table, data) {
            var values = [];
            var newFilter = "";

            //console.log('This is the Data Table For Closest Table ' + table );
            var tableget = $("table ");
            var searchstring = "> tbody > tr > td[data-product="+data+"]";
            //console.log('Search String   ',searchstring);
            tableget.find(searchstring).each(function () {

                newFilter = $(this).html();

                if($.inArray(newFilter, values)<0) {
                    //add to array
                    values.push(newFilter); // <- basic JS see Array.push
                    //console.log('This Filter Pushed ', newFilter);
                    //console.log('Values After Pushed  ', values);

                }
            });

            //console.log('This Values Pushed ', values);

            return values;

        }

        function showSelectedValues(table,slug,value) {

            //console.log('Slug: ', slug);
            //console.log('Value: ', value);
            //console.log('Table: ', table);

            $("table tr td").each(function() {
                var tdValue = $(this).html();
                var tdSlug = $(this).attr('data-product');
                //console.log('This td has the slug: '+tdSlug+' and the Value '+tdValue);
                //console.log('Td Typeof Slug: '+ typeof tdSlug+' and typeof Value '+ typeof tdValue);
                //console.log('Function Typeof Slug: '+ typeof slug+' and typeof Value '+ typeof value);

                if (tdValue === value && tdSlug === slug) {
                    $(this).closest('tr').show(200);
                    //console.log('We found it, The Parent', $(this).parent());
                }

            });

        }

        function resetfilter(id) {
            $('table tr td').each(function() {
                $(this).closest('tr').hide(50);
                $(this).closest('tr').show(200);
            });
            $('select[class=alltable-search]').each( function () {
                $(this).find('option:eq(0)').prop('selected', true);
            });
        }

        $(document).ready(function () {
            var items = $('.alltable-search');
            var dataproduct;
            var datatable;

            var options;

            if (items) {
                $('.alltable-search').each(function (){
                    dataproduct = $(this).attr("data-product");
                    //console.log('This is the Data Product For this Select ' + dataproduct );
                    datatable = $('table');
                    //console.log('This is the Data Table For this Select ' + dataproduct );
                    options = gettdValuesfromTable(datatable, dataproduct);
                    //console.log('Select Options ' + options );
                    //console.log('TypeOF Options ' + typeof options );
                    //console.log("THISSSSS Select", $(this).html());
                    $.each(options, function(key, value) {
                        //console.log("THISSSSS Value", value);
                        //console.log("THISSSSS Key", key);
                        var thisselect = $('#'+dataproduct);
                        var selectTable = thisselect.attr('data-table');
                        //console.log("THISSSSS Select", thisselect);

                        thisselect.append(`<option value="${value}"> 
                                       ${value} 
                                  </option>`);
                    });

                });
            }



            $('.resetfilteralltable').on('click', function () {
                var tableid = $(this).attr("id");
                if(tableid) {
                    resetfilter(tableid);
                }
            });

            $(".alltable-search").change(function () {
                var filterdata = getenabledfilters();
                var tableid = $(this).attr("data-table");

                if (filterdata) {
                    $('table tr td').each(function() {
                        $(this).closest('tr').hide(50);
                    });
                }

                $(filterdata).each(function (){
                    var filterSlug = "";
                    var filterValue = "";
                    var filterTable = tableid;

                    if ($(this)[0].slug) {
                        filterSlug = $(this)[0].slug;
                    }

                    if ($(this)[0].value) {
                        filterValue = $(this)[0].value;
                    }
                    //console.log('Slug: ', filterSlug);
                    //console.log('Value: ', filterValue);
                    //console.log('Table: ', tableid);
                    showSelectedValues(filterTable, filterSlug, filterValue);

                });

                //console.log('Select is Changed');
                //console.log('Table Id ', tableid);
            });

            });
    }

    counter() {
        function inViewport() {
            $.fn.isInViewport = function () {

                var elementTop = $(this).offset().top;

                var elementBottom = elementTop + $(this).outerHeight() / 2;

                var viewportTop = $(window).scrollTop();

                var viewportHalf = viewportTop + $(window).height() / 2;

                var viewportBottom = viewportTop + $(window).height();

                return viewportBottom > elementBottom;

            };
        }

        inViewport();

        $(window).on('load resize scroll', function () {
            $('.numbers--body').each(function () {

                if ($(this).isInViewport()) {
                    $(this).addClass('inview');

                    const numbers = $(this).children().children('.h1');

                    numbers.each(function () {
                        const value = $(this).attr('data-value');
                        const start = $(this).attr('data-start');
                        var Cont = {val: start}, NewVal = value;

                        const $this = $(this).get(0);

                        TweenLite.to(Cont, 2.5, {
                            val: NewVal, roundProps: "val", onUpdate: function () {
                                $this.innerHTML = Cont.val
                            }, ease: Power4.easeOut
                        });
                        $(window).off('scroll');
                    });
                }
            });
        });
    }

}


const app = new App();
export default app;
