import $ from 'jquery';
import 'slick-carousel';

class Carousel {

    run() {
        this.mobileCarousel();
        this.bigHeroCarousel();
        this.caseCarousel();
        this.pdpCarousel();
        this.standardSlider();
    }

    mobileCarousel() {

        $('.mobile-carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            arrows: false,
            dots: true,
            responsive: [{
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }, ]
        });

    }


    standardSlider(){
        $(".standard-slider").each(function() {
            const linkArrow = $(this).attr('data-arrow');
            $(this).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                speed: 1000,
                arrows: true,
                dots: true,
                prevArrow: "<svg class='a-left control-c prev slick-prev' viewBox='0 0 30 54'><use xlink:href='"+ linkArrow +"'></use></svg>",
                nextArrow: "<svg class='a-right control-c next slick-next' viewBox='0 0 30 54'><use xlink:href='"+ linkArrow + "'></use></svg>",
            });
        });
    }

    bigHeroCarousel() {
        $(".big-hero-carousel").each(function() {
            const linkArrow = $(this).attr('data-arrow');
            $(this).slick({
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                speed: 1000,
                arrows: true,
                dots: true,
                prevArrow: "<svg class='a-left control-c prev slick-prev' viewBox='0 0 30 54'><use xlink:href='"+ linkArrow +"'></use></svg>",
                nextArrow: "<svg class='a-right control-c next slick-next' viewBox='0 0 30 54'><use xlink:href='"+ linkArrow + "'></use></svg>",
                asNavFor: '.big-hero-carousel-title',
            });
        });
        $(".big-hero-carousel-title").each(function() {
            $(this).slick({
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                speed: 1000,
                arrows: true,
                asNavFor: '.big-hero-carousel',
                arrows: false,
                fade: true,
            });
        })
    }


    caseCarousel() {
        $(".case-carousel").each(function() {
            $(this).slick({
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                speed: 1000,
                arrows: false,
                dots: true,
                responsive: [{
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                ]
            });
        })
    }

    pdpCarousel() {
        $(".pdp-carousel").each(function() {
            $(this).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                speed: 600,
                arrows: false,
                dots: true,
            });
        });

        $(".product-carousel").each(function() {
            $(this).slick({
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                speed: 1000,
                arrows: false,
                dots: true,
                responsive: [{
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                ]
            });
        });

        $(".category-carousel").each(function() {
            $(this).slick({
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                speed: 600,
                arrows: false,
                dots: true,
            });
        });

        $('.tabs-title a').on('click', function (e) {
            $(".product-carousel").each(function() {
                $(this).slick("getSlick").refresh();
            })
        });

    }

}

const carousel = new Carousel();
export default carousel;