import $ from 'jquery';
import foundation from 'foundation-sites';
import App from './modules/app';
import Parallax from './modules/parallax';
import Carousel from './modules/carousel';

window.addEventListener('DOMContentLoaded', function(){
    $(document).foundation();
    App.run();
    Parallax.run();
    Carousel.run();
 });
